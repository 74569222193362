import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import "./CaseListPage.css";

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuBar } from "components/MenuBar";
import { Sidebar } from "components/Sidebar";
import { AdminOptionsBreadcrumb } from "components/AdminOptionsBreadcrumb";
import { SearchBox } from "components/SearchBox";
import { Button } from "components/Button";
import { Case } from "models/case.models";
import { DraggableModal } from "components/DraggableModal";
import { Textbox } from "components/Textbox";
import { FormInput } from "components/FormInput";
import { FormSelect } from "components/FormSelect";

import CasesSVG from "icons/cases.svg";
import LeftArrowSVG from "icons/left-long-arrow.svg";
import RightArrowSVG from "icons/right-long-arrow.svg";
import SaveSVG from "icons/save-dark.svg";
import CancelSVG from "icons/cancel-dark.svg";
import EditSVG from "icons/edit.svg";

export const CaseListPage = (): JSX.Element => {
  const [cases, setCases] = useState<Case[]>([]);
  const [interviewOptions, setInterviewOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const navigate = useNavigate();

  const fetchCases = useCallback(() => {
    axios
      .get("https://skaffolding.com/cases/api/cases/")
      .then((response) => setCases(response.data))
      .catch((error) => console.error("Failed to fetch cases", error));
  }, []);

  const fetchInterviewTypes = () => {
    axios
      .get("https://skaffolding.com/interviews/api/interviewtypes/")
      .then((response) => {
        const formattedOptions = response.data.map(
          (interview: { id: string; interview_name: string }) => ({
            value: interview.id,
            label: interview.interview_name,
          })
        );
        setInterviewOptions(formattedOptions);
      })
      .catch((error) => {
        console.error("Error fetching interview types:", error);
      });
  };

  useEffect(() => {
    fetchInterviewTypes();
  }, []);

  useEffect(() => {
    fetchCases();
  }, []);

  // Modal related
  const [createCaseFormData, setCreateCaseFormData] = useState<Case>({
    case_name: "",
    interview_type: "",
  });

  const [modalState, setModalState] = useState({
    addCaseModal: false,
  });

  const openModal = (modalName: string) =>
    setModalState({ ...modalState, [modalName]: true });
  const closeModal = (modalName: string) => {
    setModalState({ ...modalState, [modalName]: false });
    // Clear the form data after the modal is closed
    setCreateCaseFormData({ case_name: "", interview_type: "" });
  };

  const handleModalInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCreateCaseFormData({ ...createCaseFormData, [name]: value });
  };

  const handleModalSelectChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setCreateCaseFormData({
      ...createCaseFormData,
      interview_type: selectedOption ? selectedOption.value : "",
    });
  };

  const handleModalSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Modal form submitted:", createCaseFormData);
    axios
      .post(`https://skaffolding.com/cases/api/cases/`, createCaseFormData)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error creating interview:", error);
      });
    closeModal("addCaseModal");
  };

  // Cases table related
  const columnHelper = createColumnHelper<Case>();
  const columns = [
    columnHelper.accessor("id", {
      cell: (info) => info.getValue(),
      header: () => <span>ID</span>,
    }),
    columnHelper.accessor("case_name", {
      cell: (info) => info.getValue(),
      header: () => <span>Case Name</span>,
    }),
    columnHelper.accessor("interview_type", {
      cell: (info) => info.getValue(),
      header: () => <span>Interview Type</span>,
    }),
    columnHelper.accessor("id", {
      id: "action",
      cell: (info) => (
        <Button
          buttonName="Edit"
          rightButtonImage={EditSVG}
          onClick={() => navigate(`/case/${info.getValue()}`)}
        ></Button>
      ),
      header: () => <span>Action</span>,
    }),
  ];

  return (
    <div className="case-list-wrapper">
      <MenuBar />
      <div className="content-wrapper">
        <Sidebar />

        <div className="interview-panel">
          <AdminOptionsBreadcrumb
            mainCrumb={true}
            subtitle="Cases"
            subtitleIcon={CasesSVG}
          />
          <div className="top-panel">
            <SearchBox length="long" />
            <Button
              buttonName={"Create New Case"}
              buttonImage={CasesSVG}
              onClick={() => openModal("addCaseModal")}
            />
          </div>
          <CasesTable cases={cases} columns={columns}></CasesTable>
        </div>
      </div>
      <DraggableModal
        isOpen={modalState.addCaseModal}
        onRequestClose={() => closeModal("addCaseModal")}
        handle={`.interview-modal-header`}
      >
        <div className="interview-modal">
          <div className="interview-modal-header">
            <div className="header-contents">
              <Textbox text={"Create New Case"} />
              <Textbox text={createCaseFormData.case_name || "Case Name"} />
            </div>
            <div className="header-close-button">
              <img onClick={() => closeModal("addCaseModal")} src={CancelSVG} />
            </div>
          </div>

          <div className="interview-modal-contents">
            <div className="interview-modal-fields">
              <FormInput
                value={createCaseFormData.case_name}
                name="case_name"
                onChange={handleModalInputChange}
                label="Case Name"
                required={true}
              />
              <FormSelect
                options={interviewOptions}
                onChange={handleModalSelectChange}
                placeholder={"Select Interview Type"}
              />
            </div>
          </div>

          <div className="interview-modal-actions">
            <Button
              buttonName={"Save"}
              buttonImage={SaveSVG}
              onClick={handleModalSubmit}
            />
            <Button
              onClick={() => closeModal("addCaseModal")}
              buttonName={"Cancel"}
              buttonImage={CancelSVG}
            />
          </div>
        </div>
      </DraggableModal>
    </div>
  );
};

interface CaseTableProps {
  cases: Case[];
  columns: ColumnDef<Case, any>[];
}
const CasesTable = ({ cases, columns }: CaseTableProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const table = useReactTable({
    data: cases,
    columns,
    state: {
      pagination: { pageIndex, pageSize },
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    pageCount: Math.ceil(cases.length / pageSize),
  });

  return (
    <div className="cases-table-container">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <div className="pagination-left">
          <span>Items per page: </span>
          <select
            value={pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="pagination-right">
          <select
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          >
            {[...Array(table.getPageCount())].map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          <span> of {table.getPageCount()} pages</span>
          <Button
            onClick={() => table.previousPage()}
            buttonImage={LeftArrowSVG}
            variant={table.getCanPreviousPage() ? "standard" : "inactive"}
          ></Button>
          <Button
            onClick={() => table.nextPage()}
            rightButtonImage={RightArrowSVG}
            variant={table.getCanNextPage() ? "standard" : "inactive"}
          ></Button>
        </div>
      </div>
    </div>
  );
};
