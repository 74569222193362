import { DraggableModal } from "components/DraggableModal";
import "./AddQuestionModal.css";
import { ChangeEvent, useEffect, useState } from "react";
import { FormInput } from "components/FormInput";

import SaveSVG from "icons/right-sidebar-save.svg";
import CancelSVG from "icons/right-sidebar-cancel.svg";
import { Button } from "components/Button";
import { Textbox } from "components/Textbox";

import { CreateQuestion, Question } from "models/question.models";
import { validateQuestionCreationData } from "validation/QuestionValidation";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  variableType: string;
  onAddQuestion: (question: Question) => void;
}

export const AddQuestionModal = ({
  isOpen,
  onRequestClose,
  variableType,
  onAddQuestion,
}: Props): JSX.Element | null => {
  const [question, setQuestion] = useState<CreateQuestion>(() => ({
    variable_name: "",
    variable_type: "",
    label: "",
  }));

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (isOpen) {
      setQuestion({
        variable_name: "",
        variable_type: variableType,
        label: "",
      });
      setFormErrors({});
    }
  }, [isOpen]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      [name]: value,
    }));
  };

  const handleModalSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationErrors = validateQuestionCreationData(question);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;
    }

    onAddQuestion(question);
    onRequestClose();
  };

  return (
    <DraggableModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      handle={`.screen-modal-header`}
    >
      <div className="screen-modal">
        <div className="screen-modal-header">
          <div className="header-contents">
            <Textbox text={"Create New Variable"} />
            <Textbox text={question.variable_name || "Variable Name"} />
          </div>
          <div className="header-close-button">
            <img onClick={onRequestClose} src={CancelSVG} />
          </div>
        </div>

        <div className="screen-modal-contents">
          <div className="screen-modal-fields">
            <FormInput
              label="Variable Name"
              required={true}
              value={question.variable_name}
              name="variable_name"
              onChange={handleInputChange}
              length={500}
              error={formErrors.variable_name}
            />
          </div>

          <div className="screen-modal-fields">
            <FormInput
              label="Variable Label"
              value={question.label}
              name="label"
              onChange={handleInputChange}
              length={500}
            />
          </div>
        </div>

        <div className="screen-modal-actions">
          <Button
            buttonName={"Save"}
            buttonImage={SaveSVG}
            onClick={handleModalSubmit}
          />
          <Button
            onClick={onRequestClose}
            buttonName={"Cancel"}
            buttonImage={CancelSVG}
          />
        </div>
      </div>
    </DraggableModal>
  );
};
