import { Column } from "components/Column";
import EditSVG from "../../icons/interview-table-edit.svg";

import "./InterviewTable.css";

// interface TableData {
//   id: any[];
//   interviewName: any[];
//   published: any[];
//   ownerName: any[];
//   creationDate: any[];
//   action: any[];
// }

interface Props {
  tableData: any;
}

export const InterviewTable = ({ tableData }: Props): JSX.Element => {
  return (
    <div className="interview-table">
      <Column columnName="ID" data={tableData.id} />
      <Column columnName="Interview Name" data={tableData.interviewName} />
      <Column columnName="Published" data={tableData.published} />
      <Column columnName="Owner Name" data={tableData.ownerName} />
      <Column columnName="Creation Date" data={tableData.creationDate} />
      <Column
        columnName="Action"
        data={tableData.id.map((id: any) => (
          <a href={`/interview/${id}`}>
            <img className="action-button" src={EditSVG} alt="Edit Icon" />
          </a>
        ))}
      />
    </div>
  );
};
