import "./InterviewDesignBottomPanelButton.css";

interface Props {
  buttonIcon: any;
  buttonName: any;
  selected?: boolean;
  onClick?: any;
}

export const InterviewDesignBottomPanelButton = ({
  buttonIcon,
  buttonName,
  selected = false,
  onClick,
}: Props): JSX.Element => {
  return (
    <div className={`button ${selected ? "selected" : ""}`} onClick={onClick}>
      <img className="button-icon" src={buttonIcon} alt={buttonName} />
      <div className="button-text">{buttonName}</div>
    </div>
  );
};
