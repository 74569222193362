import "./InterviewDesignBottomNavBar.css";
import { Dropdown } from "components/Dropdown";
import { DropdownItem } from "components/DropdownItem";

import ContactsSVG from "icons/admin-contact-settings.svg";
import SortSVG from "icons/interview-design-page-sort.svg";
import SearchSVG from "icons/interview-design-page-search.svg";
import UndoSVG from "icons/interview-design-page-undo.svg";
import RedoSVG from "icons/interview-design-page-redo.svg";
import SaveSVG from "icons/interview-design-page-save-2.svg";

interface Props {
  onSaveClick: (event: any) => void;
}

export const InterviewDesignBottomNavBar = ({
  onSaveClick,
}: Props): JSX.Element => {
  const items = [
    <DropdownItem key="1" optionName="Option 1" iconSVG={ContactsSVG} />,
    <DropdownItem key="2" optionName="Option 2" iconSVG={ContactsSVG} />,
    <DropdownItem key="3" optionName="Option 3" iconSVG={ContactsSVG} />,
  ];
  return (
    <div className="nav-bar">
      <div className="nav-bar-left-side">
        <Dropdown DropdownItems={items} />
        <a href="#">
          <img className="nav-bar-icon" src={SortSVG} alt="" />
        </a>
        <a href="#">
          <img className="nav-bar-icon" src={SearchSVG} alt="" />
        </a>
        <a href="#">
          <img className="nav-bar-icon" src={UndoSVG} alt="" />
        </a>
        <a href="#">
          <img className="nav-bar-icon" src={RedoSVG} alt="" />
        </a>
      </div>
      <div className="nav-bar-right-side">
        <a href="#" onClick={onSaveClick}>
          <img className="nav-bar-icon" src={SaveSVG} alt="" />
        </a>
      </div>
    </div>
  );
};
