import React, { useState } from "react";
import "./ScreenDesignScreenItemsNavBar.css";

import TextVarSVG from "icons/screen-design-page-textVar.svg";
import NumberVarSVG from "icons/screen-design-page-numberVar.svg";
import BooleanVarSVG from "icons/screen-design-page-booleanVar.svg";
import SelectVarSVG from "icons/screen-design-page-selectVar.svg";
import DateVarSVG from "icons/screen-design-page-dateVar.svg";
import InstructionVarSVG from "icons/screen-design-page-instructionVar.svg";

import SaveSVG from "icons/interview-design-page-save-2.svg";
import { AddQuestionModal } from "components/AddQuestionModal";

interface Props {
  onAddQuestion: (question: any) => void;
  onSaveClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const ScreenDesignScreenItemsNavBar = ({
  onSaveClick,
  onAddQuestion,
}: Props): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [currentVariableType, setCurrentVariableType] = useState("");

  const handleIconClick = (variableType: string) => {
    setCurrentVariableType(variableType);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentVariableType("");
  };

  const navItems = [
    { src: TextVarSVG, alt: "Text Variable", type: "text" },
    { src: NumberVarSVG, alt: "Number Variable", type: "number" },
    { src: BooleanVarSVG, alt: "Boolean Variable", type: "yes/no" },
    { src: SelectVarSVG, alt: "Select Variable", type: "multiples_choices" },
    { src: DateVarSVG, alt: "Date Variable", type: "date" },
    {
      src: InstructionVarSVG,
      alt: "Instruction Variable",
      type: "instruction",
    },
  ];

  return (
    <div>
      <div className="nav-bar">
        <div className="nav-bar-left-side">
          {navItems.map((item, index) => (
            <a href="#" key={index} onClick={() => handleIconClick(item.type)}>
              <img className="nav-bar-icon" src={item.src} alt={item.alt} />
            </a>
          ))}
        </div>
        <div className="nav-bar-right-side">
          <a href="#" onClick={onSaveClick}>
            <img className="nav-bar-icon" src={SaveSVG} alt="Save" />
          </a>
        </div>
      </div>
      <AddQuestionModal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        variableType={currentVariableType}
        onAddQuestion={onAddQuestion}
      ></AddQuestionModal>
    </div>
  );
};
