import axios from "axios";
import { CreateInterview } from "models/interview.models";

export const InterviewService = {
  getInterviews: async () => {
    const response = await axios.get(
      "https://skaffolding.com/interviews/api/interviewtypes/"
    );
    return response.data;
  },

  createInterview: async (data: CreateInterview): Promise<any> => {
    const response = await axios.post(
      "https://skaffolding.com/interviews/api/interviewtypes/",
      data
    );
    return response.data;
  },

  //   getInterviewById: async (id: string) => {
  //     const response = await axios.get(
  //       `https://skaffolding.com/interviews/api/interviewtypes/${id}/`
  //     );
  //     return response.data;
  //   },

  //   updateInterview: async (id: string, data: { interview_name: string }) => {
  //     const response = await axios.put(
  //       `https://skaffolding.com/interviews/api/interviewtypes/${id}/`,
  //       data
  //     );
  //     return response.data;
  //   },

  //   deleteInterview: async (id: string) => {
  //     const response = await axios.delete(
  //       `https://skaffolding.com/interviews/api/interviewtypes/${id}/`
  //     );
  //     return response.data;
  //   },
};
