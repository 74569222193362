import "./RightSidebarScreensList.css";

import { useEffect, useState } from "react";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import AddSVG from "icons/add.svg";
import EclipseSVG from "icons/right-sidebar-eclipse.svg";
import SaveSVG from "icons/right-sidebar-save.svg";
import EditSVG from "icons/right-sidebar-edit.svg";
import DeleteSVG from "icons/right-sidebar-delete.svg";
import CancelSVG from "icons/right-sidebar-cancel.svg";

import { FormInput } from "components/FormInput";
import { FormSelect } from "components/FormSelect";
import { DraggableModal } from "components/DraggableModal";
import { Textbox } from "components/Textbox";
import { Button } from "components/Button";
import { RadioButton } from "components/RadioButton";

import { ScreenService } from "services/ScreenService";

import { Screen, CreateScreen, Option } from "models/screen.models";

import { validateScreenCreationData } from "validation/ScreenValidation";

const options: Option[] = [
  { value: "repeatable", label: "Repeatable" },
  { value: "single", label: "Single" },
];

interface Props {
  interviewId: any;
}

export const RightSidebarScreensList = ({
  interviewId,
}: Props): JSX.Element => {
  const [screenValues, setScreenValues] = useState<{ [key: string]: Screen }>(
    {}
  );

  const {
    data: screens,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ["screens", interviewId],
    queryFn: () => ScreenService.getScreens(interviewId),
  });

  useEffect(() => {
    if (screens) {
      const initialScreenValues = screens.reduce((acc: any, screen: Screen) => {
        acc[screen.id] = {
          ...screen,
          screen_name: screen.screen_name,
          screen_type: screen.screen_type,
        };
        return acc;
      }, {} as { [key: number]: Screen });

      setScreenValues(initialScreenValues);
    }
  }, [screens]);

  const handleInputChange = (
    screenId: string,
    field: keyof Screen,
    value: string
  ) => {
    setScreenValues((prevValues) => ({
      ...prevValues,
      [screenId]: {
        ...prevValues[screenId],
        [field]: value,
      },
    }));
  };

  const handleSaveScreen = (screenId: string) => {
    const screenData = screenValues[screenId];
    axios
      .put(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/${screenId}/`,
        screenData
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error saving screen:", error);
      });
  };

  const handleDeleteScreen = (screenId: string) => {
    axios
      .delete(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/${screenId}/`
      )
      .then((response) => {
        setScreenValues((prevValues) => {
          const newValues = { ...prevValues };
          delete newValues[screenId];
          return newValues;
        });
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error deleting screen:", error);
      });
  };

  // Modal related
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const [modalState, setModalState] = useState({
    addScreenModal: false,
  });

  const openModal = (modalName: string) =>
    setModalState({ ...modalState, [modalName]: true });
  const closeModal = (modalName: string) => {
    setModalState({ ...modalState, [modalName]: false });
    setCreateScreenFormData({ screen_name: "", screen_type: "" });
    setFormErrors({});
  };

  // Create screen in modal
  const [createScreenFormData, setCreateScreenFormData] =
    useState<CreateScreen>({
      screen_name: "",
      screen_type: "",
    });

  const handleModalRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCreateScreenFormData({
      ...createScreenFormData,
      screen_type: event.target.value,
    });
  };

  const handleModalInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCreateScreenFormData({ ...createScreenFormData, [name]: value });
  };

  const {
    mutate: createScreen,
    isPending: isPendingModal,
    isError: isErrorModal,
    error: errorModal,
  } = useMutation({
    mutationFn: ({
      interviewId,
      data,
    }: {
      interviewId: string;
      data: CreateScreen;
    }) => ScreenService.createScreen(interviewId, data),
    onSuccess: (responseData) => {
      setScreenValues((prevValues) => {
        const screen: Screen = responseData;
        prevValues[screen.id] = {
          ...screen,
          screen_name: screen.screen_name,
          screen_type: screen.screen_type,
        };
        const newValues = { ...prevValues };
        return newValues;
      });

      closeModal("addScreenModal");
    },
    onError: (error) => {
      console.error("Error creating screen:", error);
    },
  });

  const handleModalSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationErrors = validateScreenCreationData(createScreenFormData);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;
    }

    setFormErrors({});
    createScreen({ interviewId, data: createScreenFormData });
  };

  return (
    <div className="screens-container">
      <a
        href="#"
        className="screen-add-button"
        onClick={() => openModal("addScreenModal")}
      >
        <img className="screen-add-icon" src={AddSVG} />
        <div className="screen-add-text">Add New Screen</div>
      </a>
      <div className="screens-list">
        {Object.keys(screenValues).map((screenId, index) => (
          <ScreenItem
            key={screenId}
            screen={screenValues[screenId]}
            index={index}
            onInputChange={handleInputChange}
            onSave={handleSaveScreen}
            onDelete={handleDeleteScreen}
          />
        ))}
      </div>
      <DraggableModal
        isOpen={modalState.addScreenModal}
        onRequestClose={() => closeModal("addScreenModal")}
        handle={`.screen-modal-header`}
      >
        <div className="screen-modal">
          <div className="screen-modal-header">
            <div className="header-contents">
              <Textbox text={"Create New Screen"} />
              <Textbox
                text={createScreenFormData.screen_name || "Screen Name"}
              />
            </div>
            <div className="header-close-button">
              <img
                onClick={() => closeModal("addScreenModal")}
                src={CancelSVG}
              />
            </div>
          </div>

          <div className="screen-modal-contents">
            <div className="screen-modal-fields">
              <FormInput
                value={createScreenFormData.screen_name}
                name="screen_name"
                onChange={handleModalInputChange}
                label="Screen Name"
                required={true}
                error={formErrors.screen_name}
              />
            </div>

            <div className="screen-modal-fields">
              <FormInput label="Screen Label" />
            </div>

            <div className="screen-type-details">
              <div className="screen-type-options">
                <RadioButton
                  name="screen_type"
                  value="single"
                  checked={createScreenFormData.screen_type === "single"}
                  onChange={handleModalRadioChange}
                  label="Single Screen"
                />
                <RadioButton
                  name="screen_type"
                  value="repeatable"
                  checked={createScreenFormData.screen_type === "repeatable"}
                  onChange={handleModalRadioChange}
                  label="Repeatable Screen"
                />
              </div>

              <div className="screen-type-description">
                <div className="screen-type-description-text">
                  Screen type - Description of screen type as to how it would
                  function
                </div>
              </div>
              {formErrors.screen_type && (
                <div className="radio-button-error">
                  {formErrors.screen_type}
                </div>
              )}
            </div>
          </div>

          <div className="screen-modal-actions">
            <Button
              buttonName={"Save & Continue Interview Design"}
              buttonImage={SaveSVG}
              onClick={handleModalSubmit}
            />
            <Button
              buttonName={"Save & Go to Screen Builder"}
              buttonImage={SaveSVG}
              onClick={handleModalSubmit}
            />
            <Button
              buttonName={"Cancel"}
              buttonImage={CancelSVG}
              onClick={() => closeModal("addScreenModal")}
            />
          </div>
        </div>
      </DraggableModal>
    </div>
  );
};

const ScreenItem = ({
  screen,
  index,
  onInputChange,
  onSave,
  onDelete,
}: {
  screen: Screen;
  index: number;
  onInputChange: (screenId: string, field: keyof Screen, value: string) => void;
  onSave: (screenId: string) => void;
  onDelete: (screenId: string) => void;
}) => (
  <div className="screen-item" key={screen.id}>
    <div className="screen-item-container">
      <div className="screen-number-container">
        <img
          className="screen-number-eclipse"
          src={EclipseSVG}
          alt="Eclipse Icon"
        />
        <div className="screen-number-box">
          <div className="screen-number">{index + 1}</div>
        </div>
      </div>
      <div className="screen-details-container">
        <FormInput
          value={screen.screen_name}
          border="off"
          length={275}
          use="default"
          onChange={(e) =>
            onInputChange(screen.id, "screen_name", e.target.value)
          }
        />
        <FormSelect
          options={options}
          value={
            options.find((option) => option.value === screen.screen_type) ||
            null
          }
          onChange={(selectedOption: any) =>
            onInputChange(screen.id, "screen_type", selectedOption.value)
          }
          placeholder="Select screen type"
        />
      </div>
      <div className="screen-actions-container">
        <img
          onClick={() => onSave(screen.id)}
          className="screen-action-icon"
          src={SaveSVG}
          alt="Save icon"
          role="button"
        />
        <img
          onClick={() =>
            (window.location.href = `${window.location.pathname}/${screen.id}`)
          }
          className="screen-action-icon"
          src={EditSVG}
          alt="Edit icon"
        />
        <img
          onClick={() => onDelete(screen.id)}
          className="screen-action-icon"
          src={DeleteSVG}
          alt="Delete icon"
        />
      </div>
    </div>
  </div>
);
