import "./Divider.css";

interface Props {
  dividerWidth?: any;
}

export const Divider = ({ dividerWidth }: Props): JSX.Element => {
  return (
    <div className={`divider-wrapper`}>
      <div className={`divider`}></div>
    </div>
  );
};
