import "./Textbox.css";

interface Props {
  background?: "gray" | "dark" | "light";
  corner?: "sharp" | "round";
  border?: "off" | "on";
  text: string;
  label?: string;
}

export const Textbox = ({
  background = "gray",
  corner = "sharp",
  border = "off",
  text = "",
  label,
}: Props): JSX.Element => {
  return (
    <div className={`text-box ${background} ${corner} ${border}`}>
      <div className="text-name">{text}</div>
    </div>
  );
};
