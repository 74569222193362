import React from "react";
import "./FormLabel.css";

interface Props {
  children: React.ReactNode;
}

export const FormLabel = ({ children }: Props): JSX.Element => {
  return (
    <div className={`form-label-wrapper`}>
      <div className="form-label-holder">
        <div className="form-label">
          <React.Fragment>{children}</React.Fragment>
        </div>
        <div className="form-required">*</div>
      </div>
    </div>
  );
};
