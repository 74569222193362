import "./TemplatesTable.css";

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import { Button } from "components/Button";
import { DraggableModal } from "components/DraggableModal";
import { FormInput } from "components/FormInput";
import { Textbox } from "components/Textbox";

import { Template, createTemplate } from "models/template.models";

import EditSVG from "icons/edit.svg";
import DownloadSVG from "icons/download.svg";
import DeleteSVG from "icons/delete.svg";
import LeftArrowSVG from "icons/left-long-arrow.svg";
import RightArrowSVG from "icons/right-long-arrow.svg";
import SaveDarkSVG from "icons/save-dark.svg";
import CancelSVG from "icons/cancel-dark.svg";
import AddSVG from "icons/add.svg";

interface TemplatesTableProps {
  interviewId: string;
}

export const TemplatesTable = ({ interviewId }: TemplatesTableProps) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const fetchTemplates = useCallback(() => {
    axios
      .get(
        `https://skaffolding.com/templates_files/api/template-documents/${interviewId}/`
      )
      .then((response) => setTemplates(response.data))
      .catch((error) => console.error("Failed to fetch templates", error));
  }, []);

  const handleDownload = (url: string) => {
    window.location.href = url;
  };

  const handleDelete = (templateId: string) => {
    axios
      .delete(
        `https://skaffolding.com/templates_files/api/template-documents/${interviewId}/${templateId}/delete/`
      )
      .then(() => {
        fetchTemplates(); // Refresh templates after deletion
      })
      .catch((error) => console.error("Failed to delete template", error));
  };

  useEffect(() => {
    fetchTemplates();
  }, [interviewId]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const columnHelper = createColumnHelper<Template>();
  const columns = [
    columnHelper.accessor("template_name", {
      cell: (info) => info.getValue(),
      header: () => <span>Template Name</span>,
    }),
    columnHelper.accessor("upload_date", {
      cell: (info) => info.getValue(),
      header: () => <span>Upload Date</span>,
    }),

    columnHelper.accessor("id", {
      id: "action",
      cell: (info) => {
        const template = templates.find((t) => t.id === info.getValue());
        return (
          <>
            <Button
              rightButtonImage={EditSVG}
              onClick={() => openModal("addTemplateModal", template?.id)}
            />
            <Button
              rightButtonImage={DownloadSVG}
              onClick={() => handleDownload(template?.document_file || "")}
            />
            <Button
              rightButtonImage={DeleteSVG}
              onClick={() => handleDelete(template?.id || "")}
            />
          </>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  const table = useReactTable({
    data: templates,
    columns,
    state: {
      pagination: { pageIndex, pageSize },
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    pageCount: Math.ceil(templates.length / pageSize),
  });

  // Modal related
  const [modalState, setModalState] = useState({
    addTemplateModal: false,
  });

  // Create interview in modal
  const [templateFormData, setTemplateFormData] = useState<createTemplate>({
    template_name: "",
    document_file: null,
  });
  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>(
    null
  );
  const [isEditing, setIsEditing] = useState(false);

  const openModal = (modalName: string, templateId?: string) => {
    if (templateId) {
      const templateToEdit = templates.find(
        (template) => template.id === templateId
      );
      if (templateToEdit) {
        setTemplateFormData({
          template_name: templateToEdit.template_name,
          document_file: null,
        });
        setIsEditing(true);
        setCurrentTemplateId(templateId);
      }
    } else {
      setIsEditing(false);
      setCurrentTemplateId(null);
      setTemplateFormData({ template_name: "", document_file: null });
    }
    setModalState({ ...modalState, [modalName]: true });
  };
  const closeModal = (modalName: string) => {
    setModalState({ ...modalState, [modalName]: false });
    setTemplateFormData({ template_name: "", document_file: null });
  };

  const handleModalInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setTemplateFormData({ ...templateFormData, [name]: value });
  };

  const handleModalFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setTemplateFormData({ ...templateFormData, document_file: file });
  };

  const handleModalSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isEditing && !templateFormData.document_file) {
      alert("Please upload a file to create a new template.");
      return;
    }

    const url = isEditing
      ? `https://skaffolding.com/templates_files/api/template-documents/${interviewId}/${currentTemplateId}/edit/`
      : `https://skaffolding.com/templates_files/api/template-documents/${interviewId}/upload/`;

    const method = isEditing ? "put" : "post";

    axios({
      method: method,
      url: url,
      data: templateFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response.data);
        fetchTemplates();
      })
      .catch((error) => {
        console.error("Error creating template:", error);
      });
    closeModal("addTemplateModal");
  };

  return (
    <div className="templates-table">
      <div className="template-add-btn">
        <Button
          buttonName="Add a new template"
          rightButtonImage={AddSVG}
          onClick={() => openModal("addTemplateModal")}
        />
      </div>
      <div className="cases-table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <div className="pagination-left">
            <span>Items per page: </span>
            <select
              value={pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="pagination-right">
            <select
              value={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
            >
              {[...Array(table.getPageCount())].map((_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
            <span> of {table.getPageCount()} pages</span>
            <Button
              onClick={() => table.previousPage()}
              buttonImage={LeftArrowSVG}
              variant={table.getCanPreviousPage() ? "standard" : "inactive"}
            ></Button>
            <Button
              onClick={() => table.nextPage()}
              rightButtonImage={RightArrowSVG}
              variant={table.getCanNextPage() ? "standard" : "inactive"}
            ></Button>
          </div>
        </div>
      </div>
      <DraggableModal
        isOpen={modalState.addTemplateModal}
        onRequestClose={() => closeModal("addTemplateModal")}
        handle={`.template-add-modal-header`}
      >
        <div className="template-add-modal">
          <div className="template-add-modal-header">
            <div className="header-contents">
              <Textbox text={"Add New Template"} />
              <Textbox
                text={templateFormData.template_name || "Template Name"}
              />
            </div>
            <div className="header-close-button">
              <img
                onClick={() => closeModal("addTemplateModal")}
                src={CancelSVG}
              />
            </div>
          </div>

          <div className="template-add-modal-contents">
            <div className="template-add-modal-fields">
              <FormInput
                value={templateFormData.template_name}
                name="template_name"
                onChange={handleModalInputChange}
                label="Template Name"
                required={true}
              />
              <input
                type="file"
                name="document_file"
                onChange={handleModalFileChange}
                required
              />
            </div>
          </div>

          <div className="interview-modal-actions">
            <Button
              buttonName={"Save"}
              buttonImage={SaveDarkSVG}
              onClick={handleModalSubmit}
            />
            <Button
              onClick={() => closeModal("addTemplateModal")}
              buttonName={"Cancel"}
              buttonImage={CancelSVG}
            />
          </div>
        </div>
      </DraggableModal>
    </div>
  );
};
