import "./InterviewDesignPage.css";

// libs
import axios from "axios";
import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { linter, lintGutter } from "@codemirror/lint";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// components
import { MenuBar } from "components/MenuBar";
import { AdminOptionsBreadcrumb } from "components/AdminOptionsBreadcrumb";
import { Sidebar } from "components/Sidebar";
import { Divider } from "components/Divider";
import { FormLabel } from "components/FormLabel";
import { FormInput } from "components/FormInput";
import { InterviewDesignBottomPanelButton } from "components/InterviewDesignBottomPanelButton";
import { InterviewDesignBottomNavBar } from "components/InterviewDesignBottomNavBar";
import { Button } from "components/Button";

// icons
import InterviewSVG from "icons/admin-interview.svg";
import SaveSVG from "icons/interview-design-page-save.svg";
import PublishSVG from "icons/interview-design-page-publish.svg";
import InterviewLogics from "icons/interview-design-page-interviewLogics.svg";
import TemplatesSVG from "icons/interview-design-page-templates.svg";
import InterviewSettingsSVG from "icons/interview-design-page-interviewSettings.svg";

// sub
import { TemplatesTable } from "./TemplatesTable/TemplatesTable";
import { CustomTemplatesTable } from "./TemplatesTable/CustomizedTemplatesTable";
import { InterviewRightSidebar } from "./InterviewRightSidebar/InterviewRightSidebar";
import useCodeMirrorConfig from "hooks/useCodeMirrorConfig";

export const InterviewDesignPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [formValues, setFormValues] = useState({
    interview_name: "",
  });
  const [formValues2, setFormValues2] = useState({
    logic: "",
  });

  const [selectedNavOption, setselectedNavOption] = useState("LogicsOption");
  const handleNavOptionClick = (option: any) => {
    setselectedNavOption(option);
  };

  useEffect(() => {
    // Function to fetch interview details
    axios
      .get(`https://skaffolding.com/interviews/api/interviewtypes/${id}/`)
      .then((response) => {
        setFormValues({ interview_name: response.data.interview_name });
        setFormValues2({ logic: response.data.logic });
        console.log(response.data.logic);
      })
      .catch((error) => {
        console.error("Error fetching interview details:", error);
      });
  }, [id]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleLogicChange = (val: any, viewUpdate: any) => {
    setFormValues2({ ...formValues2, logic: val });
  };

  const handleNameSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formValues);
    axios
      .put(
        `https://skaffolding.com/interviews/api/interviewtypes/${id}/`,
        formValues
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleLogicSubmit = (event: any) => {
    event.preventDefault();
    axios
      .patch(
        `https://skaffolding.com/interviews/api/interviewtypes/${id}/`,
        formValues2
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const { isLoading, error, getHints, testValidator } = useCodeMirrorConfig(
    id || ""
  );

  return (
    <div className="interview-design-wrapper">
      <MenuBar />
      <div className="content-wrapper">
        <Sidebar />
        <div className="interview-panel">
          <AdminOptionsBreadcrumb
            mainCrumb={false}
            subtitle="Interview"
            subtitleIcon={InterviewSVG}
          />
          <Divider dividerWidth="interview-panel-divider-width" />
          <div className="interview-design-top-panel">
            <div className="top-panel-button-wrapper">
              <Button
                buttonName="Save"
                buttonImage={SaveSVG}
                onClick={handleNameSubmit}
              />
              <Button buttonName="Publish" buttonImage={PublishSVG} />
            </div>
          </div>
          <Divider dividerWidth="interview-panel-divider-width" />
          <div className="interview-form">
            <FormLabel>Interview Name</FormLabel>
            <FormInput
              value={formValues.interview_name}
              onChange={handleNameChange}
              name={"interview_name"}
            />
          </div>
          <div className="interview-design-bottom-panel">
            <div className="bottom-panel-button-wrapper">
              <InterviewDesignBottomPanelButton
                buttonIcon={InterviewLogics}
                buttonName="Interview Logics"
                selected={selectedNavOption === "LogicsOption"}
                onClick={() => handleNavOptionClick("LogicsOption")}
              />
            </div>
            <div className="bottom-panel-button-wrapper-1">
              <InterviewDesignBottomPanelButton
                buttonIcon={TemplatesSVG}
                buttonName="Templates"
                selected={selectedNavOption === "TemplatesOption"}
                onClick={() => handleNavOptionClick("TemplatesOption")}
              />
            </div>
            <div className="bottom-panel-button-wrapper-2">
              <InterviewDesignBottomPanelButton
                buttonIcon={TemplatesSVG}
                buttonName="Custom Templates"
                selected={selectedNavOption === "CustomTemplatesOption"}
                onClick={() => handleNavOptionClick("CustomTemplatesOption")}
              />
            </div>
            <div className="bottom-panel-button-wrapper-3">
              <InterviewDesignBottomPanelButton
                buttonIcon={InterviewSettingsSVG}
                buttonName="Interview Settings"
                selected={selectedNavOption === "SettingsOption"}
                onClick={() => handleNavOptionClick("SettingsOption")}
              />
            </div>
          </div>
          {selectedNavOption === "LogicsOption" && (
            <div className="interview-logic-panel">
              <InterviewDesignBottomNavBar onSaveClick={handleLogicSubmit} />
              <CodeMirror
                height="493px"
                maxHeight="1000px"
                width="inherit"
                value={formValues2.logic}
                onChange={handleLogicChange}
                extensions={[
                  autocompletion({
                    activateOnTyping: false,
                    override: [getHints],
                  }),
                  linter(testValidator),
                  lintGutter(),
                ]}
              />
            </div>
          )}
          {selectedNavOption === "TemplatesOption" && (
            <div className="interview-templates-panel">
              <TemplatesTable interviewId={id || ""} />
            </div>
          )}
          {selectedNavOption === "CustomTemplatesOption" && (
            <div className="interview-templates-panel">
              <CustomTemplatesTable interviewId={id || ""} />
            </div>
          )}
        </div>

        <InterviewRightSidebar interviewId={id} />
      </div>
    </div>
  );
};
