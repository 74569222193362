import axios from "axios";
import { CreateScreen } from "models/screen.models";

export const ScreenService = {
  getScreens: async (interviewId: string): Promise<any> => {
    const response = await axios.get(
      `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/`
    );
    return response.data;
  },

  createScreen: async (
    interviewId: string,
    data: CreateScreen
  ): Promise<any> => {
    const response = await axios.post(
      `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/`,
      data
    );
    return response.data;
  },
};
