import React from "react";
import Select, { StylesConfig, Props } from "react-select";

interface Option {
  value: string;
  label: string;
}

const customStyles: StylesConfig<Option, false> = {
  container: (provided, { isFocused }) => ({
    ...provided,

    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",

    outline: isFocused ? "" : "",
  }),

  control: (provided, { isFocused }) => ({
    ...provided,
    borderRadius: "0px",
    minHeight: "35px",
    height: "35px",
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: "0px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? "#783CF9" : "",
    "&:hover": {
      backgroundColor: isSelected ? "#783CF9" : "#F9F6FF",
    },
    "&:active": {
      color: "white",
      backgroundColor: isSelected ? "#783CF9" : "#9766FFD4",
    },
    height: "35px",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    height: "35px",
  }),
};

export const FormSelect: React.FC<Props<Option, false>> = (props) => {
  return <Select {...props} styles={customStyles} />;
};
