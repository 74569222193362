import "./ScreenDesignPage.css";

import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { linter, lintGutter } from "@codemirror/lint";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createColumnHelper } from "@tanstack/react-table";

import { MenuBar } from "components/MenuBar";
import { AdminOptionsBreadcrumb } from "components/AdminOptionsBreadcrumb";
import { Sidebar } from "components/Sidebar";
import { Divider } from "components/Divider";
import { FormInput } from "components/FormInput";
import { InterviewDesignBottomPanelButton } from "components/InterviewDesignBottomPanelButton";
import { InterviewDesignBottomNavBar } from "components/InterviewDesignBottomNavBar";
import { Button } from "components/Button";
import { ScreenDesignScreenItemsNavBar } from "components/ScreenDesignScreenItemsNavBar";
import { ScreenItemsTable } from "components/ScreenItemsTable";
import { RightSidebarScreenDesign } from "components/RightSidebarScreenDesign";
import { GridsDisplay } from "components/GridsDisplay";

import { Question } from "models/question.models";
import { Container, DesignScreen, Screen } from "models/screen.models";

import InterviewSVG from "icons/admin-interview.svg";
import SaveSVG from "icons/interview-design-page-save.svg";
import PreviewSVG from "icons/screen-design-page-preview.svg";
import LogicSVG from "icons/interview-design-page-interviewLogics.svg";
import screenItemsSVG from "icons/screen-design-page-screenItems.svg";
import settingsSVG from "icons/interview-design-page-interviewSettings.svg";
import EditSVG from "icons/screen-design-page-edit.svg";
import DeleteSVG from "icons/screen-design-page-delete.svg";

import {
  containerListTocontainerResponse,
  containerResponseToContainerList,
} from "helpers/transformer.helpers";
import { getVariableTypeImage } from "helpers/common.helpers";

import useCodeMirrorConfig from "hooks/useCodeMirrorConfig";

export const ScreenDesignPage = (): JSX.Element => {
  const { screenId, interviewId } = useParams<{
    screenId: string;
    interviewId: string;
  }>();

  if (!screenId || !interviewId) {
    throw new Error("Interview Id or Screen Id is undefined");
  }

  const [formValues, setFormValues] = useState<DesignScreen>({
    screen_name: "",
    screen_type: "",
    logic: "",
    containers: [],
  });

  const [selectedNavOption, setselectedNavOption] =
    useState("ScreenItemsOption");
  const [questions, setQuestions] = useState<{ [key: string]: any }>([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const fetchQuestions = useCallback(() => {
    axios
      .get(
        `https://skaffolding.com/interviews/api/screens/${screenId}/questions/`
      )
      .then((response) => {
        const initialQuestions: { [key: number]: any } = {};
        response.data.forEach((question: any) => {
          initialQuestions[question.id] = question;
        });
        setQuestions(initialQuestions);
      })
      .catch((error) => console.error("Failed to fetch questions", error));
  }, [screenId]);

  const fetchScreens = useCallback(() => {
    axios
      .get(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/${screenId}/`
      )
      .then((response) => {
        const data = response.data;
        const containers = containerResponseToContainerList(
          data.containers,
          questions
        );

        setFormValues({
          screen_name: data.screen_name,
          screen_type: data.screen_type,
          logic: data.logic || "",
          containers: containers,
        });
      })
      .catch((error) => console.error("Error fetching screen details:", error));
  }, [interviewId, screenId, questions]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  useEffect(() => {
    fetchScreens();
  }, [questions, fetchScreens]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleLogicChange = (val: any) => {
    setFormValues({ ...formValues, logic: val });
  };

  const handleContainerChange = (containers: Container[]) => {
    const convertedContainers = containerListTocontainerResponse(containers);
    axios
      .patch(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/${screenId}/`,
        { containers: convertedContainers }
      )
      .then((response) => {
        console.log("Containers saved successfully:", response.data);
        setFormValues({ ...formValues, containers: containers });
      })
      .catch((error) => {
        console.error("Error saving containers:", error);
      });
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    axios
      .patch(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/${screenId}/`,
        { screen_name: formValues.screen_name }
      )
      .then((response) => console.log(response.data))
      .catch((error) => console.error("Error submitting form:", error));
  };

  const handleLogicSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    axios
      .patch(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/${screenId}/`,
        { logic: formValues.logic }
      )
      .then((response) => console.log(response.data))
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleAddQuestion = (newQuestion: Question) => {
    axios
      .post(
        `https://skaffolding.com/interviews/api/screens/${screenId}/questions/`,
        newQuestion
      )
      .then((response) => {
        const question: Question = response.data;
        setQuestions((prevQuestions) => ({
          ...prevQuestions,
          [question.id!]: question,
        }));
        console.log(questions);
      })
      .catch((error) => {
        console.error("Error creating variable:", error);
      });
  };

  const handleEditQuestion = (question: any) => {
    setQuestions((prevQuestions) => ({
      ...prevQuestions,
      [question.id]: question,
    }));
  };

  const handleDeleteQuestion = (questionId: string) => {
    axios
      .delete(
        `https://skaffolding.com/interviews/api/screens/${screenId}/questions/${questionId}/`
      )
      .then((response) => {
        setQuestions((prevValues) => {
          const newValues = { ...prevValues };
          delete newValues[questionId];
          return newValues;
        });
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error deleting question:", error);
      });
  };

  const handleRowClick = (row: any) => {
    setSelectedRow(row);
  };

  const columnHelper = createColumnHelper<Question>();
  const columns = [
    columnHelper.accessor("variable_name", {
      cell: (info) => {
        const variableType = info.row.original.variable_type;
        const variableTypeImage = getVariableTypeImage(variableType);
        return (
          <div>
            <img
              className="table-img"
              src={variableTypeImage}
              alt={variableType}
            />
            <span>{info.getValue()}</span>
          </div>
        );
      },
      header: () => <span>Variable Name</span>,
    }),
    columnHelper.accessor("label", {
      cell: (info) => info.getValue(),
      header: () => <span>Variable Label</span>,
    }),
    columnHelper.accessor("id", {
      cell: (info) => {
        return (
          <div className="screen-items-actions">
            <img src={EditSVG}></img>
            <img
              onClick={(e) => {
                // stopPropagation to not allow the row to be selected
                e.stopPropagation();
                handleDeleteQuestion(info.getValue() as string);
                setSelectedRow(null);
              }}
              src={DeleteSVG}
            ></img>
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  const { isLoading, error, getHints, testValidator } =
    useCodeMirrorConfig(interviewId);

  return (
    <div className="screen-design-wrapper">
      <MenuBar />
      <div className="content-wrapper">
        <Sidebar />
        <div className="interview-panel">
          <AdminOptionsBreadcrumb
            mainCrumb={false}
            subtitle="Screen Builder"
            subtitleIcon={InterviewSVG}
          />
          <Divider dividerWidth="interview-panel-divider-width" />
          <div className="interview-design-top-panel">
            <div className="top-panel-button-wrapper">
              <Button
                buttonName="Save"
                buttonImage={SaveSVG}
                onClick={handleFormSubmit}
              />
              <Button buttonName="Preview" buttonImage={PreviewSVG} />
            </div>
          </div>
          <Divider dividerWidth="interview-panel-divider-width" />
          <div className="interview-form">
            <FormInput
              value={formValues.screen_name}
              onChange={handleInputChange}
              name={"screen_name"}
              label="Screen Name"
              required={true}
            />
          </div>
          <div className="interview-design-bottom-panel">
            <div className="bottom-panel-button-wrapper">
              <InterviewDesignBottomPanelButton
                buttonIcon={screenItemsSVG}
                buttonName="Screen Items"
                selected={selectedNavOption === "ScreenItemsOption"}
                onClick={() => setselectedNavOption("ScreenItemsOption")}
              />
            </div>
            <div className="bottom-panel-button-wrapper-1">
              <InterviewDesignBottomPanelButton
                buttonIcon={LogicSVG}
                buttonName="Logics"
                selected={selectedNavOption === "LogicsOption"}
                onClick={() => setselectedNavOption("LogicsOption")}
              />
            </div>
            <div className="bottom-panel-button-wrapper-2">
              <InterviewDesignBottomPanelButton
                buttonIcon={settingsSVG}
                buttonName="Display Grids"
                selected={selectedNavOption === "GridsOption"}
                onClick={() => setselectedNavOption("GridsOption")}
              />
            </div>
          </div>
          {selectedNavOption === "ScreenItemsOption" && (
            <div className="interview-logic-panel">
              <ScreenDesignScreenItemsNavBar
                onSaveClick={function (event: any): void {
                  console.log("Function not implemented.");
                }}
                onAddQuestion={handleAddQuestion}
              />
              <ScreenItemsTable
                columns={columns}
                data={Object.values(questions)}
                handleRowClick={handleRowClick}
                selectedRow={selectedRow}
              />
            </div>
          )}
          {selectedNavOption === "LogicsOption" && (
            <div className="interview-logic-panel">
              <InterviewDesignBottomNavBar onSaveClick={handleLogicSubmit} />
              <CodeMirror
                height="510px"
                maxHeight="1000px"
                width="inherit"
                value={formValues.logic}
                onChange={handleLogicChange}
                extensions={[
                  autocompletion({
                    activateOnTyping: false,
                    override: [getHints],
                  }),
                  linter(testValidator),
                  lintGutter(),
                ]}
              />
            </div>
          )}
          {selectedNavOption === "GridsOption" && (
            <div className="interview-templates-panel">
              <DndProvider backend={HTML5Backend}>
                <GridsDisplay
                  initialContainers={formValues.containers}
                  handleContainerChange={handleContainerChange}
                />
              </DndProvider>
            </div>
          )}
        </div>
        {selectedNavOption === "ScreenItemsOption" && selectedRow !== null && (
          <RightSidebarScreenDesign
            screenId={screenId}
            interviewId={interviewId}
            variable={selectedRow}
            onEditQuestion={handleEditQuestion}
          />
        )}
      </div>
    </div>
  );
};
