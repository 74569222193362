import Draggable from "react-draggable";
import "./DraggableModal.css";
import Modal from "react-modal";
import { JsxChild, JsxElement } from "typescript";
import { ReactNode } from "react";
import React from "react";

const styleOverrides: Modal.Styles = {
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 2,
  },
  content: {
    bottom: "unset",
    overflow: "visible",
    padding: 0,
    border: "none",
    borderRadius: 0,
    position: "static",
    background: "none",
    pointerEvents: "none",
  },
};

Modal.setAppElement("#root");

interface Props {
  isOpen: any;
  onRequestClose: any;
  children: React.ReactNode;
  handle: any;
}

export const DraggableModal = ({
  isOpen,
  onRequestClose,
  children,
  handle,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={styleOverrides}
    >
      <Draggable bounds="body" handle={handle}>
        <div className="content">
          {/* <div className="handle" /> */}
          <React.Fragment>{children}</React.Fragment>
        </div>
      </Draggable>
    </Modal>
  );
};
