import "./DropdownItem.css";

interface ItemsProps {
  StyleOverrideClassName?: any;
  optionName: any;
  iconSVG: any;
}

export const DropdownItem = ({
  StyleOverrideClassName,
  optionName,
  iconSVG,
}: ItemsProps): JSX.Element => {
  return (
    <div className="menu-button">
      <a href="#" className={`menu-item ${StyleOverrideClassName}`}>
        <div className="menu-text">{optionName}</div>
        <img className="menu-icon-right" src={iconSVG} alt={optionName}></img>
      </a>
    </div>
  );
};
