import "./global.css";
import "./styleguide.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { HomePage } from "pages/Home";
import { InterviewPage } from "pages/InterviewPage";
import { InterviewDesignPage } from "pages/InterviewDesignPage";
import { ScreenDesignPage } from "pages/ScreenDesignPage";
import reportWebVitals from "./reportWebVitals";
import { CasePage } from "pages/CasePage";
import { CaseListPage } from "pages/CaseListPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={HomePage}></Route>
        <Route path="/interviews" Component={InterviewPage}></Route>
        <Route path="/interview/:id" Component={InterviewDesignPage}></Route>
        <Route
          path="/interview/:interviewId/:screenId"
          Component={ScreenDesignPage}
        ></Route>
        <Route path="/cases" Component={CaseListPage}></Route>
        <Route path="/case/:caseId" Component={CasePage}></Route>
      </Routes>
    </BrowserRouter>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
