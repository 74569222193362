import { Question } from "models/question.models";
import { Container } from "models/screen.models";

export const containerResponseToContainerList = (
  containerResponse: any[][],
  questions: any
): Container[] | undefined => {
  if (containerResponse && questions) {
    return containerResponse.map((containerData, index) => ({
      id: (index + 1).toString(),
      items: containerData.map((itemData) => {
        const question = questions[itemData.id];
        return {
          id: itemData.id,
          text: itemData.item,
          type: question ? question.variable_type : "text", // default is text for now,
        };
      }),
    }));
  }
};

export const containerListTocontainerResponse = (
  containers: Container[]
): any[][] => {
  return containers.map((container) =>
    container.items.map((item) => ({
      id: item.id,
      item: item.text,
    }))
  );
};
