import { CreateParty } from "models/party.models";

export const validatePartyCreationData = (
  data: CreateParty
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  // Check if screen name is empty
  if (!data.role_name) {
    errors.role_name = "Party name must be selected!";
  }

  // Check if a screen type is selected (radio buttons)
  if (!data.filter_type) {
    errors.filter_type = "Filter type must be selected!";
  }

  return errors;
};
