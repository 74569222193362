import { AdminCard } from "../../components/AdminCard/AdminCard";
import { MenuBar } from "../../components/MenuBar";
import "./Home.css";

import InterviewSVG from "../../icons/admin-interview.svg";
import ContactsSettingsSVG from "../../icons/admin-contact-settings.svg";
import UserGroupSVG from "../../icons/admin-user-group.svg";
import AdminOptionsSVG from "../../icons/admin-admin-options.svg";

import { Sidebar } from "components/Sidebar";
import { AdminOptionsBreadcrumb } from "components/AdminOptionsBreadcrumb";

export const HomePage = (): JSX.Element => {
  return (
    <div className="home-main-wrapper">
      <MenuBar />
      <div className="content-wrapper">
        <Sidebar />
        <div className="interview-panel">
          <AdminOptionsBreadcrumb
            mainCrumb={true}
            subtitle="Admin Options"
            subtitleIcon={AdminOptionsSVG}
          />
          <AdminCard
            className="admin-page-interview-card"
            sourceSVG={InterviewSVG}
            optionType={"Interview"}
            optionText={"Create/modify interviews"}
          />
          <AdminCard
            className="admin-page-contact-settings-card"
            sourceSVG={ContactsSettingsSVG}
            optionType={"Contact Settings"}
            optionText={"Create/Modify your contact settings"}
          />
          <AdminCard
            className="admin-page-user-group-card"
            sourceSVG={UserGroupSVG}
            optionType={"User Group"}
            optionText={"Add/remove your users"}
          />
        </div>
      </div>
    </div>
  );
};
