import "./FormInput.css";

interface Props {
  background?: "gray" | "dark" | "light" | "none";
  corner?: "sharp" | "round";
  border?: "off" | "on";
  use?: "inactive" | "default" | "hovering" | "error" | "selected";
  length?: number;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  id?: string;
  type?: string;
  error?: string;
}

export const FormInput = ({
  background = "none",
  corner = "sharp",
  border = "on",
  use = "default",
  length,
  value,
  name,
  label,
  required = false,
  onChange,
  readOnly,
  id,
  type,
  error,
}: Props): JSX.Element => {
  return (
    <div className="form-input-container">
      <div className="form-label-container">
        {label && <label className="form-input-label">{label}</label>}
        {required && <div className="form-required">*</div>}
      </div>
      <input
        className={`form-input ${background} ${corner} ${border} ${use}`}
        value={value}
        onChange={onChange}
        name={name}
        style={length ? { width: `${length}px` } : undefined}
        readOnly={readOnly ? true : false}
        id={id}
        type={type}
      />
      <div className="form-error-container">
        {error && <span className="form-input-error">{error}</span>}
      </div>
    </div>
  );
};
