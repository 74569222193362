import "./AdminCard.css";
import SettingsSVG from "../../icons/admin-settings.svg";
// import SettingsSVG from "icons/admin-contact-settings.svg";

interface Props {
  className: any;
  sourceSVG: any;
  optionType: any;
  optionText: any;
}
export const AdminCard = ({
  className,
  sourceSVG,
  optionType,
  optionText,
}: Props): JSX.Element => {
  return (
    <div className={`admin-card ${className}`}>
      <div className="text-blocks">
        <div className="text-wrapper">{optionType}</div>
        <div className="text-wrapper-2">{optionText}</div>
      </div>
      <div className="settings">
        <div className="text-wrapper-3">Settings</div>
        <img className="settings-icon" src={SettingsSVG} alt="Settings Icon" />
      </div>
      <div className="icon-wrapper">
        <img className="img" src={sourceSVG} alt="Admin Options Icon" />
      </div>
    </div>
  );
};
