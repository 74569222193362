import "./Sidebar.css";
import HomeSVG from "icons/home.svg";
import CasesSVG from "icons/cases.svg";
import InterviewsSVG from "icons/interviews.svg";
import ContactsSVG from "icons/contacts.svg";
import AdminSVG from "icons/admin.svg";
import LogoutSVG from "icons/log-out.svg";

import MinimizeSVG from "icons/left-minimize-arrow.svg";
import MaximizeSVG from "icons/right-maximize-arrow.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Sidebar = (): JSX.Element => {
  const [isMinimized, setIsMinimized] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };
  return (
    <div className={`left-side-bar ${isMinimized ? "minimized" : "maximized"}`}>
      <div className="sidebar-top">
        <div className="minimize-container">
          <img
            className="minimize-icon"
            src={isMinimized ? MaximizeSVG : MinimizeSVG}
            alt="Minimize Icon"
            onClick={toggleSidebar}
          />
        </div>
        <div className="sidebar-top-buttons">
          <SidebarButtons
            buttonIcon={HomeSVG}
            buttonText="Home"
            isMinimized={isMinimized}
            onClick={() => handleNavigation("/")}
          />
          <SidebarButtons
            buttonIcon={CasesSVG}
            buttonText="Cases"
            isMinimized={isMinimized}
            onClick={() => handleNavigation("/cases")}
          />
          <SidebarButtons
            buttonIcon={InterviewsSVG}
            buttonText="Interviews"
            isMinimized={isMinimized}
            onClick={() => handleNavigation("/interviews")}
          />
          <SidebarButtons
            buttonIcon={ContactsSVG}
            buttonText="Contacts"
            isMinimized={isMinimized}
          />
          <SidebarButtons
            buttonIcon={AdminSVG}
            buttonText="Admin"
            isMinimized={isMinimized}
          />
        </div>
      </div>
      <div className="sidebar-bottom">
        <SidebarButtons
          buttonIcon={InterviewsSVG}
          buttonText="Interview"
          isMinimized={isMinimized}
        />
        <SidebarButtons
          buttonIcon={LogoutSVG}
          buttonText="Log out"
          isMinimized={isMinimized}
        />
      </div>
    </div>
  );
};

interface Props {
  buttonIcon: any;
  buttonText: any;
  isMinimized: boolean;
  onClick?: () => void;
}

export const SidebarButtons = ({
  buttonIcon,
  buttonText,
  isMinimized,
  onClick,
}: Props): JSX.Element => {
  return (
    <div className="button-wrapper" onClick={onClick}>
      <div className="button-container">
        <div className="button-container-2">
          <img className="button-icon" alt={buttonText} src={buttonIcon} />
          {!isMinimized && <div className="button-text">{buttonText}</div>}
        </div>
      </div>
    </div>
  );
};
