import "./SearchBox.css";
import SearchSVG from "../../icons/search-box-search.svg";

interface Props {
  variant?: "light" | "dark" | "grey";
  length?: "short" | "long";
  onClick?: () => void;
}

export const SearchBox = ({
  variant = "grey",
  length = "short",
  onClick,
}: Props): JSX.Element => {
  const boxWrapperClassName = `box-wrapper ${variant}`;
  const inputBoxClassName = `input-box ${length}`;

  return (
    <div className={boxWrapperClassName}>
      <input className={inputBoxClassName} />
      <div className="search-icon-wrapper">
        <img className="search-icon" src={SearchSVG} alt="Search Icon" />
      </div>
    </div>
  );
};
