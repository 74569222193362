import { CreateInterview } from "models/interview.models";

export const validateInterviewData = (
  data: CreateInterview
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  if (!data.interview_name || data.interview_name.trim() === "") {
    errors.interview_name = "Interview name is required!";
  }

  return errors;
};
