import { CreateScreen } from "models/screen.models";

export const validateScreenCreationData = (
  data: CreateScreen
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  // Check if screen name is empty
  if (!data.screen_name || data.screen_name.trim() === "") {
    errors.screen_name = "Screen name is required!";
  }

  // Check if screen name contains spaces
  if (/\s/.test(data.screen_name)) {
    errors.screen_name = "Screen name should not contain spaces!";
  }

  // Check if screen name contains '%'
  if (data.screen_name.includes("%")) {
    errors.screen_name = "Screen name should not contain '%'!";
  }

  // Check if a screen type is selected (radio buttons)
  if (!data.screen_type) {
    errors.screen_type = "Screen type must be selected!";
  }

  return errors;
};
