import "./MenuBar.css";
import HamburgerSVG from "../../icons/menu-bar-hamburger.svg";
import HelpSVG from "../../icons/menu-bar-help.svg";
import AdminSVG from "../../icons/menu-bar-admin.svg";
import LogoutSVG from "../../icons/menu-bar-logout.svg";

export const MenuBar = (): JSX.Element => {
  return (
    <div className="top-menu-bar">
      <div className="menu-bar-office-name">
        <img className="menu-img" alt="hamburger" src={HamburgerSVG} />
        <div className="menu-bar-text">Test Law Firm Name</div>
      </div>
      <div className="menu-bar-right">
        <div className="menu-bar-user-menu">
          <div className="image-user-name" />
          <div className="menu-bar-text">First Name</div>
        </div>
        <div className="menu-bar-help">
          <img className="menu-img" alt="Help icon" src={HelpSVG} />
          <div className="menu-bar-text">Help</div>
        </div>
        <div className="menu-bar-admin">
          <img className="menu-img" alt="Admin icon" src={AdminSVG} />
          <div className="menu-bar-text">Admin</div>
        </div>
        <div className="menu-bar-logout">
          <img className="menu-img" alt="Icon logout" src={LogoutSVG} />
          <div className="menu-bar-text">Logout</div>
        </div>
      </div>
    </div>
  );
};
