import "./GridsDisplay.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DragPreviewImage,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from "react-dnd";
import { getVariableTypeImage } from "helpers/common.helpers";
import { Container, Item } from "models/screen.models";

interface Props {
  initialContainers: Container[];
  handleContainerChange: (containers: Container[]) => void;
}

// ItemX Component
const ItemX: React.FC<{ id: string; text: string; type: string }> = React.memo(
  ({ id, text, type }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
      type: "ITEM_X",
      item: { id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (itemRef.current && isDragging) {
        preview(itemRef.current);
      }
    }, [isDragging, preview]);

    return (
      <>
        <DragPreviewImage
          connect={preview}
          src={`data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='30'><rect width='100' height='30' fill='lightgray' stroke='black' stroke-width='1'/><text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='black'>${text}</text></svg>`}
        />
        <div
          ref={drag}
          style={{
            opacity: isDragging ? 0.5 : 1,
            // background: isDragging ? "black" : "",
          }}
          className="grids-screen-item"
        >
          <img src={getVariableTypeImage(type)} />
          {text}
        </div>
      </>
    );
  }
);

// ContainerS Component
const ContainerS: React.FC<{
  id: string;
  items: Item[];
  moveItem: (itemId: string, targetContainerId: string | null) => void;
}> = React.memo(({ id, items, moveItem }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "ITEM_X",
    drop: (item: { id: string }, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      console.log(`Dropping item ${item.id} in ContainerS ${id}`);
      moveItem(item.id, id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        border: isOver ? "2px dashed blue" : "",
      }}
      id={`containerS-${id}`}
      className="containerS"
    >
      {items &&
        items.map((item) => (
          <ItemX key={item.id} id={item.id} text={item.text} type={item.type} />
        ))}
    </div>
  );
});

// GridsDisplay Component
export const GridsDisplay = React.memo(
  ({ initialContainers, handleContainerChange }: Props): JSX.Element => {
    const [containers, setContainers] =
      useState<Container[]>(initialContainers);
    const prevInitialContainersRef = useRef<Container[]>(initialContainers);
    console.log("Effect triggered", {
      prevInitialContainers: prevInitialContainersRef.current,
      currentInitialContainers: initialContainers,
    });

    const generateSequentialContainerIds = (
      containers: Container[]
    ): Container[] => {
      return containers.map((container, index) => ({
        ...container,
        id: (index + 1).toString(),
      }));
    };

    const moveItem = (
      itemId: string,
      targetContainerId: string | null,
      position?: number
    ) => {
      setContainers((prevContainers) => {
        // Create a deep clone of prevContainers
        const clonedContainers = JSON.parse(
          JSON.stringify(prevContainers)
        ) as Container[];

        // Find the source container
        const sourceContainer = clonedContainers.find((c) =>
          c.items.some((item) => item.id === itemId)
        );

        // Find the target container
        const targetContainer = targetContainerId
          ? clonedContainers.find((c) => c.id === targetContainerId)
          : null;

        // If source container does not exist, return previous state
        if (!sourceContainer) {
          return prevContainers;
        }

        // Limit items in targetContainer to 2
        if (targetContainer && targetContainer.items.length >= 2) {
          return prevContainers;
        }

        // Find the item to move
        const item = sourceContainer.items.find((item) => item.id === itemId);
        if (!item) return prevContainers;

        // Remove item from source container
        sourceContainer.items = sourceContainer.items.filter(
          (item) => item.id !== itemId
        );

        // Handle the case where targetContainer exists
        if (targetContainer) {
          // Add the item to the target container
          targetContainer.items.push(item);
          handleContainerChange(
            generateSequentialContainerIds(
              clonedContainers.filter((container) => container.items.length > 0)
            )
          );

          // Remove empty containers and return the updated state
          return clonedContainers.filter(
            (container) => container.items.length > 0
          );
        } else {
          // Create a new container with the item
          const newContainer: Container = {
            id: `${Date.now()}`,
            items: [item],
          };

          // Insert the new container at the specified position
          if (position !== undefined) {
            // if (sourceContainer.items.length === 0) position += 1;
            clonedContainers.splice(position, 0, newContainer);
          } else {
            // Add the new container to the end
            clonedContainers.push(newContainer);
          }

          // Remove the source container if empty
          let updatedContainers =
            sourceContainer.items.length > 0
              ? clonedContainers
              : clonedContainers.filter((c) => c.id !== sourceContainer.id);

          // Return updated state with non-empty containers
          updatedContainers = updatedContainers.filter(
            (c) => c.items.length > 0
          );
          handleContainerChange(
            generateSequentialContainerIds(updatedContainers)
          );
          return generateSequentialContainerIds(updatedContainers);
        }
      });
    };

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: "ITEM_X",
        drop: (item: { id: string }, monitor) => {
          if (monitor.didDrop()) {
            return;
          }
          console.log("Outer container drop detected");
          const dropIndex = getDropIndex(monitor);
          moveItem(item.id, null, dropIndex);
        },
        collect: (monitor) => ({
          isOver: monitor.isOver({ shallow: true }),
        }),
      }),
      [containers]
    );

    const getDropIndex = useCallback(
      (monitor: DropTargetMonitor) => {
        const clientOffset = monitor.getClientOffset();

        if (!clientOffset) return containers.length;
        const y = clientOffset.y;
        const containerLRect = document
          .getElementById("containerL")
          ?.getBoundingClientRect();
        if (!containerLRect) return containers.length;

        const containerSRects = containers.map((_, index) =>
          document.getElementById(`containerS-${_.id}`)?.getBoundingClientRect()
        );

        let dropIndex = containers.length;
        for (let i = 0; i < containerSRects.length; i++) {
          const rect = containerSRects[i];
          if (rect && y < rect.top + rect.height / 2) {
            dropIndex = i;
            break;
          }
        }
        return dropIndex;
      },
      [containers]
    );

    return (
      <div
        ref={drop}
        style={{
          border: isOver ? "2px dashed green" : "",
        }}
        id={"containerL"}
        className="containerL"
      >
        {containers.map((container) => (
          <ContainerS
            key={container.id}
            id={container.id}
            items={container.items}
            moveItem={moveItem}
          />
        ))}
      </div>
    );
  }
);
