import { useState, useCallback } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

interface ApiResponse<T> {
  data: T | null;
  isLoading: boolean;
  error: string | null;
  fetchData: (
    configOverride?: AxiosRequestConfig
  ) => Promise<AxiosResponse<T> | undefined>;
  setConfig: (newConfig: AxiosRequestConfig) => void;
}

function useApi<T>(initialConfig: AxiosRequestConfig): ApiResponse<T> {
  const [config, setConfig] = useState<AxiosRequestConfig>(initialConfig);
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(
    async (
      configOverride?: AxiosRequestConfig
    ): Promise<AxiosResponse<T> | undefined> => {
      console.log("Logging fetch api calls");
      const finalConfig = { ...config, ...(configOverride || {}) };

      setIsLoading(true);
      setError(null);

      try {
        const response: AxiosResponse<T> = await axios(finalConfig);
        setData(response.data);
        return response;
      } catch (err) {
        const axiosError = err as AxiosError;
        setError(axiosError.message || "An error occurred");
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [config]
  );

  return { data, isLoading, error, fetchData, setConfig };
}

export default useApi;
