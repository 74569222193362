import "./InterviewPage.css";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { MenuBar } from "components/MenuBar";
import { InterviewTable } from "components/InterviewTable";
import { AdminOptionsBreadcrumb } from "components/AdminOptionsBreadcrumb";
import { Sidebar } from "components/Sidebar";
import { SearchBox } from "components/SearchBox";
import { Button } from "components/Button";
import { DraggableModal } from "components/DraggableModal";
import { Textbox } from "components/Textbox";
import { FormInput } from "components/FormInput";

import InterviewSVG from "icons/admin-interview.svg";
import SaveSVG from "icons/save-dark.svg";
import CancelSVG from "icons/cancel-dark.svg";

import { CreateInterview, TransposedData } from "models/interview.models";
import { InterviewService } from "services/InterviewService";
import { validateInterviewData } from "validation/InterviewValidation";

export const InterviewPage: React.FC = () => {
  // Initialize the initial state with empty arrays for each property
  const initialTransposedData: TransposedData = {
    id: [],
    interviewName: [],
    published: [],
    ownerName: [],
    creationDate: [],
    action: [],
  };

  // Define state to store the fetched data
  const [interviewData, setInterviewData] = useState<TransposedData>(
    initialTransposedData
  );

  const {
    isPending,
    isError,
    data: interviews,
    error,
  } = useQuery({
    queryKey: ["interviews"],
    queryFn: InterviewService.getInterviews,
  });

  useEffect(() => {
    if (interviews) {
      const formattedData = interviews.map((interview: any) => ({
        id: interview.id,
        interviewName: interview.interview_name,
        published: "Yes",
        ownerName: "None",
        creationDate: "2023-01-01",
        action: "Edit",
      }));

      // Transpose the data
      const transposedData: TransposedData = {
        id: [],
        interviewName: [],
        published: [],
        ownerName: [],
        creationDate: [],
        action: [],
      };

      formattedData.forEach((interview: any) => {
        transposedData.id.push(interview.id.toString());
        transposedData.interviewName.push(interview.interviewName);
        transposedData.published.push(interview.published);
        transposedData.ownerName.push(interview.ownerName);
        transposedData.creationDate.push(interview.creationDate);
        transposedData.action.push(interview.action);
      });
      setInterviewData(transposedData);
    }
  }, [interviews]);

  // Modal related
  const [modalState, setModalState] = useState({
    addInterviewModal: false,
  });

  const openModal = (modalName: string) =>
    setModalState({ ...modalState, [modalName]: true });
  const closeModal = (modalName: string) => {
    setModalState({ ...modalState, [modalName]: false });
    // Clear the form data after the modal is closed
    setCreateInterviewFormData({ interview_name: "" });
    setFormErrors({});
  };

  // Create interview in modal
  const [createInterviewFormData, setCreateInterviewFormData] =
    useState<CreateInterview>({
      interview_name: "",
    });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const handleModalInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateInterviewFormData({
      ...createInterviewFormData,
      [e.target.name]: e.target.value,
    });
  };

  const {
    mutate: createInterview,
    isPending: isPendingModal,
    isError: isErrorModal,
    error: errorModal,
  } = useMutation({
    mutationFn: (data: CreateInterview) =>
      InterviewService.createInterview(data),
    onSuccess: (responseData) => {
      const newInterview = {
        id: responseData.id.toString(),
        interviewName: responseData.interview_name,
        published: "Yes",
        ownerName: "None",
        creationDate: "2023-01-01",
        action: "Edit",
      };

      setInterviewData((prevData) => ({
        id: [...prevData.id, newInterview.id],
        interviewName: [...prevData.interviewName, newInterview.interviewName],
        published: [...prevData.published, newInterview.published],
        ownerName: [...prevData.ownerName, newInterview.ownerName],
        creationDate: [...prevData.creationDate, newInterview.creationDate],
        action: [...prevData.action, newInterview.action],
      }));

      closeModal("addInterviewModal");
    },
    onError: (error) => {
      console.error("Error creating interview:", error);
    },
  });

  const handleModalSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationErrors = validateInterviewData(createInterviewFormData);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;
    }

    setFormErrors({});
    createInterview(createInterviewFormData);
  };

  return (
    <div className="interview-page-wrapper">
      <MenuBar />
      <div className="content-wrapper">
        <Sidebar />

        <div className="interview-panel">
          <AdminOptionsBreadcrumb
            mainCrumb={false}
            subtitle="Interview"
            subtitleIcon={InterviewSVG}
          />
          <div className="top-panel">
            <SearchBox length="long" />
            <Button
              buttonName={"Create New Interview"}
              buttonImage={InterviewSVG}
              onClick={() => openModal("addInterviewModal")}
            />
          </div>
          <InterviewTable tableData={interviewData} />
        </div>
      </div>
      <DraggableModal
        isOpen={modalState.addInterviewModal}
        onRequestClose={() => closeModal("addInterviewModal")}
        handle={`.interview-modal-header`}
      >
        <div className="interview-modal">
          <div className="interview-modal-header">
            <div className="header-contents">
              <Textbox text={"Create New Interview"} />
              <Textbox
                text={
                  createInterviewFormData.interview_name || "Interview Name"
                }
              />
            </div>
            <div className="header-close-button">
              <img
                onClick={() => closeModal("addInterviewModal")}
                src={CancelSVG}
              />
            </div>
          </div>

          <div className="interview-modal-contents">
            <div className="interview-modal-fields">
              <FormInput
                value={createInterviewFormData.interview_name}
                name="interview_name"
                onChange={handleModalInputChange}
                label="Interview Name"
                required={true}
                error={formErrors.interview_name}
              />
            </div>
          </div>

          <div className="interview-modal-actions">
            <Button
              buttonName={"Save"}
              buttonImage={SaveSVG}
              onClick={handleModalSubmit}
            />
            <Button
              onClick={() => closeModal("addInterviewModal")}
              buttonName={"Cancel"}
              buttonImage={CancelSVG}
            />
          </div>
        </div>
      </DraggableModal>
    </div>
  );
};
