import "./AdminOptionsBreadcrumb.css";
import ArrowSVG from "../../icons/admin-breadcrumb-arrow.svg";

interface Props {
  mainCrumb: any;
  subtitle: any;
  subtitleIcon: any;
}

export const AdminOptionsBreadcrumb = ({
  mainCrumb,
  subtitle,
  subtitleIcon,
}: Props): JSX.Element => {
  return (
    <div className="breadcrumb-wrapper">
      {!mainCrumb && ( // Render only if mainCrumb is false
        <div className="breadcrumb-admin-options">
          <div className="text-block">Admin Options</div>
          <img className="arrow-sign-icon" alt="Arrow Icon" src={ArrowSVG} />
        </div>
      )}
      <div className="breadcrumb-subtitle">
        <img className="subtitle-icon" src={subtitleIcon} alt="Subtitle icon" />
        <div className="subtitle-text-wrapper">
          <div className="subtitle-text-block">{subtitle}</div>
        </div>
      </div>
    </div>
  );
};
