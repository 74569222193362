import "./CasePage.css";

import { MenuBar } from "components/MenuBar";
import { Sidebar } from "components/Sidebar";
import { useCallback, useEffect, useState } from "react";

import CaseDetailsSVG from "icons/home.svg";
import RolesSVG from "icons/user-group.svg";
import CaseScreenSVG from "icons/interview-screen.svg";
import TemplatesSVG from "icons/folder.svg";
import CrossSVG from "icons/cross.svg";
import PhoneSVG from "icons/phone.svg";
import EmailSVG from "icons/email.svg";
import SaveSVG from "icons/save-dark.svg";

import { FormInput } from "components/FormInput";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Case } from "models/case.models";
import { FormSelect } from "components/FormSelect";
import { Column } from "components/Column";
import { Question } from "models/question.models";
import { Screen, ScreenWithQuestions } from "models/screen.models";
import { CaseScreen } from "components/CaseScreen";
import { Button } from "components/Button";

export const CasePage = (): JSX.Element => {
  const { caseId } = useParams<{
    caseId: string;
  }>();

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [rolesData, setRolesData] = useState({});
  const [caseData, setCaseData] = useState<Case>({});
  const [interviewData, setInterviewData] = useState<{
    interview_logic: string;
    screens: ScreenWithQuestions[];
  } | null>(null);

  const fetchContacts = useCallback(() => {
    axios
      .get<Contact[]>("https://skaffolding.com/contacts/api/")
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch contacts", error);
      });
  }, []);

  const fetchCase = useCallback(() => {
    axios
      .get(`https://skaffolding.com/cases/api/cases/${caseId}/`)
      .then((response) => {
        setCaseData(response.data);
      })
      .catch((error) => console.error("Failed to fetch questions", error));
  }, [caseId]);

  const fetchInterviewData = useCallback(
    async (interviewTypeId: string | undefined) => {
      try {
        // Fetch interview logic
        const logicResponse = await axios.get(
          `https://skaffolding.com/interviews/api/interviewtypes/${interviewTypeId}/`
        );
        const interview_logic = logicResponse.data.logic;

        // Fetch screens
        const screensResponse = await axios.get(
          `https://skaffolding.com/interviews/api/interviewtypes/${interviewTypeId}/screens/`
        );
        const screens: Screen[] = screensResponse.data;

        // Fetch questions for each screen
        const screensWithQuestions: ScreenWithQuestions[] = await Promise.all(
          screens.map(async (screen) => {
            const questionsResponse = await axios.get(
              `https://skaffolding.com/interviews/api/screens/${screen.id}/questions/`
            );
            const questions: Question[] = questionsResponse.data;
            return { ...screen, questions };
          })
        );

        return {
          interview_logic,
          screens: screensWithQuestions,
        };
      } catch (error) {
        console.error("Error fetching interview data:", error);
        throw error;
      }
    },
    []
  );

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  useEffect(() => {
    fetchCase();
  }, [fetchCase]);

  useEffect(() => {
    if (caseData && caseData.id) {
      fetchInterviewData(caseData.interview_type)
        .then(setInterviewData)
        .catch((error) =>
          console.error("Error fetching interview data:", error)
        );
    }
  }, [caseData, fetchInterviewData]);

  useEffect(() => {
    if (caseData && caseData.id) {
      const rolesData = caseData.roles_responses;
      setRolesData(rolesData);
    }
  }, [caseData]);

  const [selectedNavOption, setselectedNavOption] =
    useState("CaseDetailsOption");

  return (
    <div className="case-main-wrapper">
      <MenuBar />
      <div className="content-wrapper">
        <Sidebar />
        <div className="case">
          <div className="case-header">
            <div className="case-name">Case Name - Test Case</div>
            <div className="case-interview-type">
              Interview Type - Test Interview
            </div>
          </div>
          <div className="case-nav-bar">
            <CaseNavIcon
              buttonImage={CaseDetailsSVG}
              selected={selectedNavOption === "CaseDetailsOption"}
              onClick={() => setselectedNavOption("CaseDetailsOption")}
            />
            <CaseNavIcon
              buttonImage={RolesSVG}
              selected={selectedNavOption === "RolesOption"}
              onClick={() => setselectedNavOption("RolesOption")}
            />
            <CaseNavIcon
              buttonImage={CaseScreenSVG}
              selected={selectedNavOption === "CaseScreenOption"}
              onClick={() => setselectedNavOption("CaseScreenOption")}
            />
            <CaseNavIcon
              buttonImage={TemplatesSVG}
              selected={selectedNavOption === "TemplatesOption"}
              onClick={() => setselectedNavOption("TemplatesOption")}
            />
          </div>
          <div className="case-main-component">
            {selectedNavOption === "CaseDetailsOption" && (
              <CaseDetails caseData={caseData} />
            )}
            {selectedNavOption === "RolesOption" && (
              <CaseRoles
                roles={caseData.roles}
                rolesData={rolesData}
                caseId={caseId || ""}
                setRolesData={setRolesData}
              />
            )}
            {selectedNavOption === "CaseScreenOption" && (
              <CaseScreen
                interviewData={interviewData!}
                rolesData={rolesData}
                contacts={contacts}
                caseData={caseData}
              />
            )}
            {selectedNavOption === "TemplatesOption" && (
              <CaseGeneratedFilesTable caseId={caseId || ""} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface CaseNavIconProps {
  buttonImage: any;
  selected?: boolean;
  onClick?: any;
}
export const CaseNavIcon = ({
  buttonImage,
  selected,
  onClick,
}: CaseNavIconProps): JSX.Element => {
  return (
    <div
      className={`case-nav-icon ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <img src={buttonImage} alt="" />
    </div>
  );
};

interface CaseDetailsProps {
  caseData: Case;
}

export const CaseDetails = ({ caseData }: CaseDetailsProps): JSX.Element => {
  return (
    <div className="case-details">
      <div className="case-details-header">
        <FormInput value="Case Details" border="off" readOnly={true} />
      </div>
      <div className="case-details-items">
        <FormInput
          label="Case name"
          value={caseData?.case_name || ""}
          border="off"
          readOnly={true}
        />
        <FormInput
          label="Interview Type"
          value={caseData?.interview_type || ""}
          border="off"
          readOnly={true}
        />
        <FormInput
          label="Case Created By (Owner)"
          value="Sharp - corner"
          border="off"
          readOnly={true}
        />
        <FormInput
          label="Creation Date"
          value="Sharp - corner"
          border="off"
          readOnly={true}
        />
        <FormInput
          label="Last Modified Date"
          value="Sharp - corner"
          border="off"
          readOnly={true}
        />
      </div>
    </div>
  );
};

interface CaseRolesProps {
  roles: any[];
  rolesData: any;
  caseId: string;
  setRolesData: any;
}

export const CaseRoles = ({
  roles,
  rolesData,
  caseId,
  setRolesData,
}: CaseRolesProps): JSX.Element => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [modifiedRolesData, setModifiedRolesData] = useState(rolesData);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(
          "https://skaffolding.com/contacts/api/"
        );
        setContacts(response.data);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  const updateRoleContacts = (roleName: string, newContacts: string[]) => {
    setModifiedRolesData((prevRolesData: any) => ({
      ...prevRolesData,
      [roleName]: newContacts,
    }));
  };

  const saveChanges = async () => {
    try {
      await axios.patch(`https://skaffolding.com/cases/api/cases/${caseId}/`, {
        roles_responses: modifiedRolesData,
      });
      setRolesData(modifiedRolesData);
    } catch (error) {
      console.error("Error saving roles responses:", error);
    }
  };

  return (
    <div className="case-roles">
      <div className="case-roles-header">
        <FormInput value="Roles & Parties" border="off" readOnly={true} />
        <Button
          onClick={saveChanges}
          buttonName={"Save"}
          rightButtonImage={SaveSVG}
        />
      </div>
      <div className="case-roles-items">
        {roles.map((role) => (
          <Role
            key={role.id}
            role={role}
            initialContactsFromBackend={
              role.role_name in modifiedRolesData
                ? modifiedRolesData[role.role_name]
                : []
            }
            allContacts={contacts}
            updateRoleContacts={updateRoleContacts}
          />
        ))}
      </div>
    </div>
  );
};

interface Contact {
  id: number;
  name: string;
  gender: string;
  dob: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zipcode: string;
  contact_types: any[];
}

interface Option {
  value: string;
  label: string;
}

interface RoleProps {
  role: any;
  initialContactsFromBackend: any;
  allContacts: Contact[];
  updateRoleContacts: any;
}

export const Role = ({
  role,
  initialContactsFromBackend,
  allContacts,
  updateRoleContacts,
}: RoleProps): JSX.Element => {
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    // Find initial contacts from fetched contacts
    const initialContacts = allContacts.filter((contact: Contact) =>
      initialContactsFromBackend.includes(contact.name)
    );
    setSelectedContacts(initialContacts);

    // Set options excluding the initial contacts
    const initialContactIds = new Set(
      initialContacts.map((c: Contact) => c.id)
    );
    setOptions(
      allContacts
        .filter((contact: Contact) => !initialContactIds.has(contact.id))
        .map((contact: Contact) => ({
          value: contact.id.toString(),
          label: contact.name,
        }))
    );
  }, [allContacts, initialContactsFromBackend]);

  const addContact = (newValue: any, actionMeta: any) => {
    if (actionMeta.action === "select-option") {
      const newContact = allContacts.find(
        (contact) => contact.id === parseInt(newValue.value, 10)
      );
      if (newContact) {
        setSelectedContacts((prevContacts) => [...prevContacts, newContact]);
        setOptions((prevOptions) =>
          prevOptions.filter((option) => option.value !== newValue.value)
        );
        // Update the role contacts in the parent component
        updateRoleContacts(role.role_name, [
          ...selectedContacts.map((c) => c.name),
          newContact.name,
        ]);
      }
    }
  };

  const removeContact = (contactToRemove: Contact) => {
    setSelectedContacts((prevContacts) =>
      prevContacts.filter((contact) => contact.id !== contactToRemove.id)
    );
    setOptions((prevOptions) => [
      ...prevOptions,
      { value: contactToRemove.id.toString(), label: contactToRemove.name },
    ]);
    // Update the role contacts in the parent component
    updateRoleContacts(
      role.role_name,
      selectedContacts
        .filter((contact) => contact.id !== contactToRemove.id)
        .map((c) => c.name)
    );
  };

  return (
    <div className="case-role-item">
      <div className="case-role-details">
        <div className="case-role-type">{role.role_label}</div>
        <div className="case-role-contacts">
          {selectedContacts.map((contact) => (
            <ContactElement
              key={contact.id}
              contact={contact}
              onRemove={() => removeContact(contact)}
            />
          ))}
        </div>
      </div>
      <div className="case-role-add">
        <FormSelect
          value={null}
          options={options}
          onChange={addContact}
          placeholder="Search and add contacts"
        />
      </div>
    </div>
  );
};

interface ContactElementProps {
  contact: Contact;
  onRemove: any;
}

export const ContactElement = ({
  contact,
  onRemove,
}: ContactElementProps): JSX.Element => {
  return (
    <div className="contact-card">
      <div className="contact-details">
        <div className="contact-name">{contact.name}</div>
        <div className="contact-address">
          {contact.address_line1}, {contact.address_line2}, {contact.city},
          {contact.state}, {contact.zipcode}
        </div>
        <div className="contact-phone-email">
          <div className="contact-phone">
            <img src={PhoneSVG} alt="" />
            <p>1111-1111-1111</p>
          </div>
          <div className="contact-email">
            <img src={EmailSVG} alt="" />
            <p>email address</p>
          </div>
        </div>
      </div>
      <img src={CrossSVG} alt="Cross Icon" onClick={onRemove} />
    </div>
  );
};

interface CaseGeneratedFilesTableProps {
  caseId: string;
}

interface GeneratedCasesData {
  file_name: string;
  last_modified: string;
}

export const CaseGeneratedFilesTable = ({
  caseId,
}: CaseGeneratedFilesTableProps): JSX.Element => {
  const [files, setFiles] = useState<GeneratedCasesData[]>([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `https://skaffolding.com/templates_files/api/generated-documents/${caseId}/`
        );
        setFiles(response.data);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, [caseId]);

  const handleDownload = async (fileName: string) => {
    try {
      const response = await axios.get(
        `https://skaffolding.com/templates_files/api/generated-documents/${caseId}/${fileName}/download/`
      );
      const fileUrl = response.data.file_url;

      window.location.href = fileUrl;
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="case-generated-files">
      <Column
        columnName="Generated Document"
        data={files.map((file) => file.file_name)}
      />

      <Column
        columnName="Generated Date"
        data={files.map((file) =>
          new Date(file.last_modified).toLocaleString()
        )}
      />
      <Column
        columnName="Action"
        data={files.map((file) => (
          <button onClick={() => handleDownload(file.file_name)}>
            Download
          </button>
        ))}
      />
    </div>
  );
};
