import TextVarSVG from "icons/screen-design-page-textVar.svg";
import NumberVarSVG from "icons/screen-design-page-numberVar.svg";
import BooleanVarSVG from "icons/screen-design-page-booleanVar.svg";
import SelectVarSVG from "icons/screen-design-page-selectVar.svg";
import DateVarSVG from "icons/screen-design-page-dateVar.svg";
import InstructionVarSVG from "icons/screen-design-page-instructionVar.svg";

 // Function to get image URL based on variable type
 export const getVariableTypeImage = (variableType: string) => {
    switch (variableType) {
      case "text":
        return TextVarSVG;
      case "number":
        return NumberVarSVG;
      case "date":
        return DateVarSVG;
      case "yes/no":
        return BooleanVarSVG;
      case "multiples_choices":
        return SelectVarSVG;
      case "instruction":
        return InstructionVarSVG;
    }
  };
