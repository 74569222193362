import "./Button.css";

interface Props {
  buttonName?: string;
  buttonImage?: string;
  rightButtonImage?: string;
  variant?: "standard" | "inactive" | "hover" | "click";
  onClick?: any;
  id?: string;
  extraClassName?: string;
}

export const Button = ({
  buttonName,
  buttonImage,
  rightButtonImage,
  variant = "standard",
  onClick,
  id,
  extraClassName,
}: Props): JSX.Element => {
  const buttonClassName = `top-panel-button ${variant} ${extraClassName}`;

  const leftImageElement = buttonImage && (
    <img
      className="top-panel-button-icon left-icon"
      src={buttonImage}
      alt={`${buttonName} left icon`}
    />
  );

  const rightImageElement = rightButtonImage && (
    <img
      className="top-panel-button-icon right-icon"
      src={rightButtonImage}
      alt={`${buttonName} right icon`}
    />
  );

  const buttonNameElement = buttonName && (
    <div className="top-panel-button-label">{buttonName}</div>
  );

  return (
    <a href="#" className={buttonClassName} onClick={onClick} id={id}>
      {leftImageElement}
      {buttonNameElement}
      {rightImageElement}
    </a>
  );
};
