import "./RightSidebarNavButton.css";

interface Props {
  buttonName: any;
  buttonImage: any;
  selected?: boolean;
  onClick?: any;
}

export const RightSidebarNavButton = ({
  buttonName,
  buttonImage,
  selected = false,
  onClick,
}: Props): JSX.Element => {
  const buttonWrapperClassName = `button-wrapper ${selected ? "selected" : ""}`;

  return (
    <a className={buttonWrapperClassName} onClick={onClick}>
      <div className="button-component">
        <img className="button-icon" src={buttonImage} alt={buttonName} />
        <p className="button-text">{buttonName}</p>
      </div>
    </a>
  );
};
