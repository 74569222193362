import "./RadioButton.css";

interface Props {
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export const RadioButton = ({
  name,
  value,
  checked,
  onChange,
  label,
}: Props): JSX.Element => {
  return (
    <label className="radio-input">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  );
};
