import { CreateQuestion } from "models/question.models";

export const validateQuestionCreationData = (
  data: CreateQuestion
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  // Check if screen name is empty
  if (!data.variable_name || data.variable_name.trim() === "") {
    errors.variable_name = "Variable name is required!";
  }

  // Check if screen name contains spaces
  if (/\s/.test(data.variable_name)) {
    errors.variable_name = "Variable name should not contain spaces!";
  }

  // Check if screen name contains '%'
  if (data.variable_name.includes("%")) {
    errors.variable_name = "Variable name should not contain '%'!";
  }

  return errors;
};
