import "./InterviewRightSidebar.css";

import { useState } from "react";

import { Divider } from "components/Divider";
import { RightSidebarNavButton } from "components/RightSidebarNavButton";
import { RightSidebarScreensList } from "components/RightSidebarScreensList";
import { RightSidebarPartiesList } from "components/RightSidebarPartiesList";
import { SearchBox } from "components/SearchBox";

import MinimizeSVG from "icons/right-sidebar-minimize.svg";
import PropertiesSVG from "icons/right-sidebar-nav-properties.svg";
import ScreensSVG from "icons/right-sidebar-nav-screens.svg";
import PartiesSVG from "icons/right-sidebar-nav-parties.svg";

interface Props {
  interviewId: any;
}

export const InterviewRightSidebar = ({ interviewId }: Props): JSX.Element => {
  const [selectedNavOption, setselectedNavOption] = useState("ScreensOption");
  const handleNavOptionClick = (option: any) => {
    setselectedNavOption(option);
  };
  return (
    <div className={`right-side-bar-panel`}>
      <div className="sidebar-top-area">
        <img className="minimize-icon" src={MinimizeSVG} alt="Minimize Icon" />
        <SearchBox length="long" />
      </div>
      <div className="navigation-bar-container">
        <Divider />
        <div className="navigation-bar">
          <RightSidebarNavButton
            buttonName={"Properties"}
            buttonImage={PropertiesSVG}
            selected={selectedNavOption === "PropertiesOption"}
            onClick={() => handleNavOptionClick("PropertiesOption")}
          />
          <RightSidebarNavButton
            buttonName={"Screens"}
            buttonImage={ScreensSVG}
            selected={selectedNavOption === "ScreensOption"}
            onClick={() => handleNavOptionClick("ScreensOption")}
          />
          <RightSidebarNavButton
            buttonName={"Parties"}
            buttonImage={PartiesSVG}
            selected={selectedNavOption === "PartiesOption"}
            onClick={() => handleNavOptionClick("PartiesOption")}
          />
        </div>
        <Divider />
      </div>
      {selectedNavOption === "ScreensOption" && (
        <RightSidebarScreensList interviewId={interviewId} />
      )}
      {selectedNavOption === "PartiesOption" && (
        <RightSidebarPartiesList interviewId={interviewId} />
      )}
    </div>
  );
};
