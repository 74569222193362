import "./Dropdown.css";

import InsertSVG from "../../icons/dropdown-insert.svg";
import ArrowSVG from "../../icons/dropdown-arrow.svg";
import PolygonSVG from "../../icons/dropdown-polygon.svg";
import { useState } from "react";

interface Props {
  StyleOverrideClassName?: any;
  DropdownItems?: any;
}

export const Dropdown = ({
  StyleOverrideClassName,
  DropdownItems = [],
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <a
        href="#"
        className={`dropdown ${StyleOverrideClassName}`}
        onClick={() => setOpen(!open)}
      >
        <img className="insert-plus-icon" src={InsertSVG} alt="Insert Icon" />
        <div className="insert-text">Insert</div>
        <img className="insert-arrow-icon" src={ArrowSVG} alt="Arrow Icon" />
      </a>

      {open && (
        <div className="dropdown-menu">
          <img className="polygon" src={PolygonSVG} alt="Polygon icon" />
          <div className="dropdown-menu-layout">
            <div className="dropdown-menu-container">
              {DropdownItems.map((item: any, index: any) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
