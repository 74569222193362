import "./Column.css";
import UpArrowSVG from "../../icons/interview-table-uparrow.svg";

interface Props {
  columnName: any;
  data: React.ReactNode[];
}

export const Column = ({ columnName, data }: Props): JSX.Element => {
  return (
    <div className="column-wrapper">
      <div className="column-header-base">
        <div className="column-name">{columnName}</div>
        <img className="header-arrow-img" src={UpArrowSVG} alt="UpArrow Icon" />
      </div>

      {data.map((item, index) => (
        <div key={index} className="column-items-base">
          <div className="column-contents">{item}</div>
        </div>
      ))}
    </div>
  );
};
